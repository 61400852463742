<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
        <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
    </div></v-row><br><br>
        <br />

        <v-card class="card">
            <v-card-title class="heading">Import Author
            </v-card-title>
            <!-- <v-card class="box">
                    <div class="flex">
                    <div></div>
                        <div>
                            <a href="https://easypariksha.s3.ap-south-1.amazonaws.com/import_excel_csv_file/Import_Instructor.csv">
                            <v-btn :loading="loading3" :disabled="loading3" color="success" class="ma-2 white--text downbtn" @click="loader = 'loading3'">
                                <v-icon left dark>mdi-cloud-download</v-icon>Download import CSV format
                            </v-btn>
                            </a>
                        </div>
                    </div>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" lg="4" sm="12">
                                <v-file-input dense v-model="file" :hint="file != null? '':'Please Select File'" persistent-hint color="rgb(1, 127, 152)" label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined show-size> </v-file-input>
                            </v-col>
                            <v-col cols="4"> </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <center>
                                    <v-btn @click="importCsv" :loading="output_load" class="button">
                                        Upload
                                        <v-icon right>mdi-cloud-upload</v-icon>
                                    </v-btn>
                                </center>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card> -->
                <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <div class="add-section">
                <v-row class="justify-content-between set-btn">
                    <div><a
                            href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/library_import_emp_member_csv.csv">
                            <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export CSV
                                Template</v-btn></a>
                    </div>
                    <div>
                        <v-btn class="button mb-2" @click="openDialog"> <v-icon left>mdi-account-plus</v-icon>Add New
                            Author</v-btn>
                    </div>
                </v-row>
                <p class="file-warn">NOTE: *File should be in CSV format.</p>

                <v-row class="justify-content-center">
                    <v-col lg="5" sm="12" md="5" class="import-box">
                        <label class="label">IMPORT CSV</label>
                        <v-file-input dense v-model="file" :hint="file != null ? '' : 'Please Select File'" persistent-hint
                            color="rgb(1, 127, 152)" label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined
                            show-size>
                        </v-file-input>
                        <v-btn @click="importCsv" :loading="output_load" class="button">
                            Upload
                            <v-icon right>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <template>
                <v-simple-table v-if="failList != null">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Author Name
                                </th>
                                <th class="text-left">Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in failList" :key="item.name">
                                <td>{{ item.name }}</td>
                                <td style="color: red;">{{ item.reason }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>

            <!--   <template v-if="load==true">
                <v-data-table :headers="headers" :items="author_list" sort-by class="elevation-1 nowrap" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <download-excel class="mar" :data="emplist" :fields="export_table_headers" worksheet="statuslist" name="Instructor Data.xls">
                               <a href="https://easycheck.s3.ap-south-1.amazonaws.com/library_sample_csv/library_import_emp_member_csv.csv">
                                <v-btn color="success" dark class="mb-2"> Export<v-icon dark>mdi-file-excel</v-icon> </v-btn></a>
                            </download-excel>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                            <span style="width: 20px;"></span>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive==true">
                            <v-icon class="mr-2" color="success" @click="toggle(item)">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                            <v-icon class="mr-2" color="error" @click="toggle(item)">mdi-close-circle-outline</v-icon>
                        </span>
                    </template>
                    <template v-slot:item.edit="{ item }">
                        <div>                         
                                <v-icon size="18" @click="editDialogFunction(item)">mdi mdi-pencil-box</v-icon>                         
                        </div>
                    </template>
                </v-data-table>
            </template>-->
            <template v-if="author_list != null">
                <v-row justify="end" class="pa-5">
                    <download-excel class="ma-3" v-if="author_list.length != 0" color="primary" dark :data="author_list"
                        :fields="table_fields" worksheet="My Worksheet" name="Author.xls">
                        <v-btn color="success" dark class="mb-2">
                            <v-icon dark>mdi-file-excel</v-icon>
                        </v-btn>
                    </download-excel>
                </v-row>
                <v-data-table :headers="headers" :items="author_list" :search="search" class="elevation-1 nowrap">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                hide-details></v-text-field>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.edit="{ item }">
                        <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                        <v-icon v-if="item" class="mr-2" @click="updateAuthor(item)">mdi mdi-pencil-box</v-icon>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                        <v-switch v-model="item.authorIsactive" @change="editItem(item)"></v-switch>
                    </template>
                    <template v-slot:item.delete="{ item }">
                        <v-icon v-if="item" class="mr-2" @click="deleteAuthor(item)"> mdi mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </template>
</div>

            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card>
                    <v-card-title class="heading">
                        <span>Add Author</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="author_name" outlined dense label="Author Name *"
                                        :hint="author_name != null ? '' : 'Please enter Author Name'"
                                        persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="description" outlined dense label="Description"
                                        :hint="description != null ? '' : 'Please enter description'"
                                        persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field type="number" v-model="displayorder" outlined dense
                                        label="Display Order *" persistent-hint
                                        :hint="displayorder != null ? '' : 'Please enter display order'"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="shortname" outlined dense label="Short Name"></v-text-field>
                                </v-col>

                            </v-row>
                        </v-container>
                        <small style="color: red;">*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="button-cancel" text @click="dialog = false">Close</v-btn>
                        <v-btn class="button" text @click="addInst">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="editDialog" persistent max-width="600px">
                <v-card>
                    <v-card-title class="heading">
                        <span>Edit Author</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="authorName" label="Author Name"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="edit_author_description"
                                        label="Description"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense type="number" v-model="display_order"
                                        label="Display Order"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense v-model="short_name" label="Short Name"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="button-cancel" text @click="editDialog = false">Close</v-btn>
                        <v-btn class="button" text @click="saveEdit(item)">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>


    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    /*
  Defines the data used by the component
*/
    data() {
        return {
            emplist: [],
            overlay: false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            org: null,
            init_loading: false,
            dialog: false,
            search: "",
            author_description: null,
            edit_author_description: null,
            author: null,
            authorId: null,
            authorName: null,
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '/home',
                },
                {
                    text: localStorage.getItem("jhhjbyj"),
                    disabled: false,
                    href: '/sublink',
                },
                {
                    text: 'Admin Master',
                    disabled: false,
                    href: '/admin-master',
                },
                {
                    text: 'Author',
                    disabled: true,
                    href: '',
                },
            ],
            file: null,
            statuslist: "",
            load: false,
            loading3: false,
            search: "",
            failList: null,
            editDialog: false,
            InstRecord: "",
            isLoading: false,
            author_list: null,

            headers: [
                { text: "Sr no", value: "srno" },
                { text: "Name", value: "author" },
                { text: "Description", value: "authorDes" },
                { text: "Display Order", value: "displayorder" },
                { text: "Short Name", value: "shortname" },
                { text: "Edit", value: "edit" },
                { text: "Toggle", value: "actions", sortable: false },
                { text: "Delete", value: "delete" },
            ],
            table_fields:
            {
                "Sr no": "srno",
                "Name": "author",
                "Description": "authorDes",
                "Display Order": "displayorder",
                "Short Name": "shortname",

            },
            editedItem: {
                id: "",
                author_name: "",
                description: "",

            },
            output_load: false,
        };
    },

    mounted() {
        this.onLoad();
    },
    methods: {
        onLoad() {
            this.init_loading = true
            axios
                .post("/admin/getAuthor")
                .then((res) => {
                    this.org = res.data.org;
                    this.author_list = res.data.author;
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },
        deleteAuthor(item) {
            const data = {
                authorId: item.authorId,
            };
            axios
                .post("/admin/deleteAuthor", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Author Deleted Successfully  !!");
                        this.onLoad();
                    } else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");

                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },
        editDialogFunction(val) {
            this.editedItem = Object.assign({}, val);
            this.editDialog = true;
        },
        updateAuthor(item) {
            this.editDialog = true;
            this.authorName = item.author;
            this.authorId = item.authorId;
            this.edit_author_description = item.authorDes;
            this.display_order = item.displayorder;
            this.short_name = item.shortname;

        },
        saveEdit(item) {
            console.log(item);
            const data = {
                authorName: this.authorName,
                authorId: this.authorId,
                authorDes: this.edit_author_description,
                displayorder: this.display_order,
                shortname: this.short_name
            };
            if (this.authorName !== null) {
                axios
                    .post("/admin/editAuthor", data)
                    .then((res) => {

                        this.sec_mode_list = res.data.author_list;
                        this.editDialog = false;
                        this.showSnackbar("#4CAF50", "Author updated successfully...");
                        this.onLoad();

                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        // this.editUserType = null;
                        axios;
                    });
            } else {
                this.showSnackbar("#b71c1c", "Please enter required Fields");
            }
        },
        openDialog() {
            this.dialog = true;
        },
        addInst() {
            if (this.author_name == null) {
                alert("* marked field is required");
            } else {
                this.dialog = false;
                const data = {
                    authorName: this.author_name,
                    authorDes: this.description,
                    displayorder: this.displayorder,
                    shortname: this.shortname
                };
                axios
                    .post("/admin/addAuthor", data)
                    .then((res) => {
                        if (res.data.msg == "success") {
                            // alert("success")
                            this.showSnackbar("green", "Author added successfully...");
                            //this.$refs.form.reset()
                            this.onLoad();
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                        this.org = res.data.org;
                        this.author_list = res.data.author_list;
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => { });
            }
        },

        importCsv() {
            let formData = new FormData();
            if (this.file !== null) {
                if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                    this.showSnackbar("red", "File must be in CSV format Only.!");
                } else {
                    this.fileStatus = true;
                    formData.append("file", this.file);
                    axios
                        .post("admin/importAuthor", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.status == "200") {
                                this.showSnackbar("#4caf50", "Author added successfully...");
                                this.onLoad();
                            }
                            this.file = null;
                            //this.onLoad();
                            this.failList = res.data.failList;
                        })
                        .catch(function () {
                            this.showSnackbar("red", "Something went wrong.")
                        })
                        .finally(() => {
                            this.output_load = false;
                        });
                }
            } else {
                this.fileStatus = false;
                this.showSnackbar("#b71c1c", "Please select file");
            }
        },
        editItem(item) {
            console.log(item);
            axios
                .post("/admin/authorIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "Author updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        checkuser() {
            alert("check user name");
        },
        checkExtention() {
            if (this.file != null) {
                var file = this.file.name;
                var ext = file.split(".").pop();
                if (ext == "csv") {
                    return true;
                } else {
                    // console.log("false")
                    // alert("Upload only CSV file!");
                    return false;
                }
            } else {
                return false;
            }
        },
    },
};
</script>

<style scoped>
.downbtn {
    float: right;
}

.add-section {
    display: block;
}

.box {
    background-color: #add8e630;
    margin: 8px !important;
    padding: 8px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .flex {
        display: block;
    }
}

.btn {
    margin: 1rem;
    padding-top: 1rem;
}
</style>
